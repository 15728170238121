<template>
  <div class="sfe-supports">
    <h2><span>сопровождение и&nbsp;Поддержка</span></h2>

    <div class="sfe-supports__btns">
      <button
        v-for="button in filteredButtons"
        :key="button.id"
        :class="['m-btn', { 'sfe-supports__btn-active': currentButton === button.id }]"
        :style="{ backgroundColor: currentButton === button.id ? button.color : '' }"
        @click="currentButton = button.id"
      >
        {{ button.text }}
      </button>
    </div>

    <sfe-support-teachers v-if="currentButton === 1" />

    <div
      v-if="currentButton === 2"
      class="sfe-supports_item sfe-supports__classruk"
    >
      <div class="sfe-supports_item__figure">
        <img
          loading="lazy"
          src="/v2/main/sfe-support/support-2-figure.webp"
          alt="figure"
        >
        <p>
          Делаю обучение для детей увлекательным, а&nbsp;для родителей комфортным.
          По&nbsp;любым вопросам вы&nbsp;можете обращаться ко&nbsp;мне!
        </p>
      </div>
      <img
        loading="lazy"
        src="/v2/main/sfe-support/support-2-person.webp"
        alt="фото Алина НИЗАМБИЕВА"
        class="sfe-supports__classruk_image"
      >
      <div class="sfe-supports_item_content">
        <h5>Алина НИЗАМБИЕВА</h5>
        <h6>Классный руководитель 9-х классов</h6>
        <p>
          Выпускница МГПУ &laquo;Детская психология&raquo;.
          <br>
          В&nbsp;работе создаю безопасную и&nbsp;принимающую среду для каждого ребенка.
          Каждый ребенок для меня&nbsp;&mdash; это целый мир, прекрасный и&nbsp;развивающийся
        </p>
      </div>
    </div>

    <div
      v-if="currentButton === 3"
      class="sfe-supports_item sfe-supports__tutor"
    >
      <div class="sfe-supports_item_content">
        <h5>Елена Смирнова</h5>
        <h6>Руководитель команды тьюторов</h6>
        <p>
          Выпускница МГПУ «Психология», «Тьютор дистанционного обучения»,
          Сертифицированный профориентолог для детей и подростков.
          <br>
          Наша миссия - раскрыть уникальный потенциал каждого ребенка
        </p>
      </div>
      <img
        loading="lazy"
        src="/v2/main/sfe-support/support-3-person.webp"
        alt="фото Елена Смирнова"
        class="sfe-supports__tutor_image"
      >
      <div class="sfe-supports_item__figure">
        <img
          loading="lazy"
          src="/v2/main/sfe-support/support-3-figure.webp"
          alt="figure"
        >
        <p>
          Поддержка + мотивация ученика&nbsp;— формула
          высокой успеваемости и&nbsp;интереса к учебе
        </p>
      </div>
    </div>

    <div
      v-if="currentButton === 4"
      class="sfe-supports_item sfe-supports__mentor"
    >
      <div class="sfe-supports_item__figure">
        <img
          loading="lazy"
          src="/v2/main/sfe-support/support-4-figure.webp"
          alt="figure"
        >
        <p>
          Родителям больше не&nbsp;нужно до вечера делать уроки с&nbsp;ребенком.
          С&nbsp;домашкой и&nbsp;вопросами помогут профессиональные педагоги
        </p>
      </div>
      <img
        loading="lazy"
        src="/v2/main/sfe-support/support-4-person.webp"
        alt="фото Виктория Воронова "
        class="sfe-supports__classruk_image"
      >
      <div class="sfe-supports_item_content">
        <h5>Виктория Воронова</h5>
        <h6>Руководитель команды наставников</h6>
        <p>
          Магистр НИУ ВШЭ направления &laquo;Педагогика и&nbsp;психология&raquo;,
          Репетитор с&nbsp;опытом более 6&nbsp;лет.
          <br>
          Наставники &laquo;дают не&nbsp;рыбку, а&nbsp;удочку&raquo; - помогаем детям
          самим находить ответы, разжигаем и&nbsp;подпитываем интерес к&nbsp;учебе
        </p>
      </div>
    </div>

    <div
      v-if="currentButton === 5"
      class="sfe-supports_video-item sfe-supports__proforientolog"
    >
      <!-- eslint-disable max-len vue/max-len -->
      <button
        class="m-btn no-tablet"
        @click="openVideoModal('https://www.youtube.com/embed/OVPRLpxrlfA?si=TK1st5zUGFHZIgWZ&amp;start=910')"
      >
        <img
          loading="lazy"
          src="/v2/main/sfe-support/support-5-image.webp"
          alt="Смотреть полностью"
          class="sfe-supports_video-item__image"
        >
        <img
          src="/v2/main/sfe/play.svg"
          loading="lazy"
          alt="play"
          class="sfe-supports_video-item__play"
        >
      </button>
      <!-- eslint-enable -->
      <img
        loading="lazy"
        src="/v2/main/sfe-support/support-5-image-mob.webp"
        alt="Смотреть полностью"
        class="sfe-supports_video-item__image tablet"
      >
      <p>
        Привет, я&nbsp;Ангелина, психолог-педагог в&nbsp;онлайн-школе «Синергия». На&nbsp;наших
        профориентационных встречах с&nbsp;учеником мы&nbsp;проводим глубокий анализ сильных
        сторон и&nbsp;выявляем естественный интерес к&nbsp;конкретной области знаний.
        Такие встречи помогают ученику определиться с&nbsp;выбором профессии
        и&nbsp;не&nbsp;сомневаться.

        <!-- eslint-disable max-len vue/max-len -->
        <button
          v-if="0"
          class="m-btn"
          @click="openVideoModal('https://www.youtube.com/embed/OVPRLpxrlfA?si=TK1st5zUGFHZIgWZ&amp;start=910')"
        >
          Смотреть полностью
          <img
            loading="lazy"
            src="/v2/main/play.svg"
            alt="Смотреть полностью"
          >
        </button>
        <nuxt-link
          to="/proforientacia"
        >
          Начните с бесплатного профтеста
          <img
            src="/v2/main/sfe/arrow.png"
            alt="arrow"
            loading="lazy"
          >
        </nuxt-link>
        <!-- eslint-enable -->
      </p>
    </div>

    <div
      v-if="currentButton === 6"
      class="sfe-supports_video-item sfe-supports__psychologist"
    >
      <p>
        Привет, я&nbsp;Ирина, детский психолог в&nbsp;онлайн-школе «Синергия». Я&nbsp;помогаю
        ученикам справляться со&nbsp;стрессом перед контрольными и&nbsp;экзаменами,
        преодолеть трудности в&nbsp;учебе и&nbsp;найти общий язык с&nbsp;одноклассниками.

        <button
          class="m-btn"
          @click="openVideoModal('https://www.youtube.com/embed/x1HMV5xzf6w?si=cXDaWZZi8qO-S2Ok')"
        >
          Смотреть полностью
          <img
            loading="lazy"
            src="/v2/main/play.svg"
            alt="Смотреть полностью"
          >
        </button>

        <span class="sfe-supports__psychologist__icons">
          <img
            v-for="icon in 5"
            :key="icon"
            loading="lazy"
            :src="`/v2/main/sfe-support/icon-${icon}.svg`"
            :alt="`icon ${icon}`"
            :class="`sfe-supports__psychologist__icon-${icon}`"
          >
        </span>
      </p>
      <button
        class="m-btn no-tablet"
        @click="openVideoModal('https://www.youtube.com/embed/x1HMV5xzf6w?si=cXDaWZZi8qO-S2Ok')"
      >
        <img
          loading="lazy"
          src="/v2/main/sfe-support/support-6-image.webp"
          alt="Смотреть полностью"
          class="sfe-supports_video-item__image"
        >
      </button>
      <img
        loading="lazy"
        src="/v2/main/sfe-support/support-6-image-mob.webp"
        alt="Смотреть полностью"
        class="sfe-supports_video-item__image tablet"
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SfeSupportTeachers from '@/components/v2/main/sfe/SfeSupportTeachers.vue';

export default {
  name: 'SfeSupports',
  components: { SfeSupportTeachers },
  props: {
    defaultButtonId: {
      type: Number,
      default: 1,
    },
    hideButtonIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      buttonTypes: [
        {
          id: 1,
          text: 'Топовые учителя',
          color: '#F199CE',
        },
        {
          id: 6,
          text: 'Психолог',
          color: '#9FE3FF',
        },
        {
          id: 5,
          text: 'Профориентолог',
          color: '#73B0F4',
        },
        {
          id: 4,
          text: 'Наставник',
          color: '#61DDBC',
        },
        {
          id: 3,
          text: 'Тьютор',
          color: '#FBD277',
        },
        {
          id: 2,
          text: 'Классный руководитель',
          color: '#B3A4EE',
        },
      ],
      currentButton: this.defaultButtonId,
    };
  },
  computed: {
    filteredButtons() {
      return this.buttonTypes.filter(({ id }) => !this.hideButtonIds.includes(id));
    },
  },
  methods: {
    ...mapActions('modals', {
      openVideoModal: 'openVideo',
    }),
  },
};
</script>

<style scoped lang="scss">
.sfe-supports {
  border-radius: 20px;
  border: 2px solid $black;
  background-color: $white-color;
  box-shadow: 4px 4px 0 0 $black;
  height: 580px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  position: relative;

  @include media-down(laptop) {
    padding-top: 16px;
  }

  @include media-down(tablet) {
    height: 560px;
  }

  @include media-down(mobile) {
    overflow: hidden;
  }

  & > div {
    padding-left: 40px;
    padding-right: 40px;

    @include media-down(desktop) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include media-down(laptop) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @include media-down(mobile) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  h2 {
    color: $black;
    font-size: 65px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.3px;
    text-transform: uppercase;
    margin-bottom: 30px;
    text-align: center;
    position: relative;

    @include media-down(tablet) {
      font-size: 30px;
      letter-spacing: -0.9px;
      line-height: 110%;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 5px;
      height: 42px;
      background-color: #fe9ce0;

      @include media-down(tablet) {
        height: 23px;
        top: 2px;
      }
    }

    &:before {
      width: 1055px;

      @media (max-width: 1153px) {
        width: 580px;
      }

      @include media-down(tablet) {
        width: 265px;
      }
    }

    &:after {
      @media (max-width: 1153px) {
        width: 470px;
        top: 70px;
      }

      @include media-down(tablet) {
        top: 35px;
        width: 215px;
      }
    }

    span {
      position: relative;
      z-index: 2;
    }
  }

  .sfe-supports__btns {
    margin-left: auto;
    margin-right: auto;
    max-width: 550px;
    text-align: center;

    @include media-down(mobile) {
      margin-bottom: -4px;
    }

    button {
      padding: 8px 18px 12px 18px;
      border-radius: 50px;
      border: 2px dashed $black;
      margin-right: 2px;
      margin-bottom: 4px;
      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 16px;
      font-weight: 700;
      line-height: 100%;
      background-color: transparent;
      cursor: pointer;
      transition: 200ms;

      &:hover {
        border-style: solid;
      }
    }

    .sfe-supports__btn-active {
      border-style: solid;
    }
  }

  .sfe-supports_item {
    margin-top: 40px;
    flex: 1;

    @include media-down(laptop) {
      margin-top: auto;
    }

    h5 {
      color: $black;
      font-size: 40px;
      font-weight: 700;
      line-height: 90%;
      letter-spacing: -1.2px;
      text-transform: uppercase;
      margin-bottom: 10px;

      @include media-down(mobile) {
        font-size: 25px;
        margin-bottom: 8px;
      }
    }

    h6 {
      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 18px;
      font-weight: 700;
      line-height: 130%;
      margin-bottom: 20px;

      @include media-down(mobile) {
        font-size: 16px;
        line-height: 100%;
        margin-bottom: 16px;
      }
    }

    .sfe-supports_item_content > p {
      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;

      @include media-down(desktop) {
        font-size: 16px;
      }

      @include media-down(laptop) {
        display: none;
      }
    }

    .sfe-supports_item__figure {
      position: relative;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        @include media-down(laptop) {
          height: 100%;
        }
      }

      p {
        position: relative;
        color: $black;
        text-align: center;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 20px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -0.2px;

        @include media-down(laptop) {
          font-size: 16px;
          line-height: 120%;
          letter-spacing: 0;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          background-repeat: no-repeat;
          width: 27px;
          height: 23px;
          transform: rotate(-11deg);

          @include media-down(laptop) {
            transform: none;
            width: 16px;
            background-size: contain;
          }
        }

        &:before {
          background-image: url('/v2/main/sfe-support/open-quotation.svg');
        }

        &:after {
          background-image: url('/v2/main/sfe-support/close-quotation.svg');
        }
      }
    }
  }

  .sfe-supports__classruk {
    display: flex;
    overflow: hidden;

    @include media-down(laptop) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .sfe-supports_item__figure {
      width: 403px;
      flex-shrink: 0;

      @include media-down(desktop) {
        width: 350px;
      }

      @include media-down(laptop) {
        width: 312px;
        height: 150px;
        transform: rotate(9deg);
      }

      p {
        width: 320px;
        top: 23%;
        left: 11%;
        transform: rotate(-10deg);

        @include media-down(desktop) {
          width: 300px;
          top: 21%;
          left: 4%;
          transform: rotate(-15deg);
        }

        @include media-down(laptop) {
          width: 270px;
          left: 20px;
          top: 28px;
          transform: rotate(-9deg);
        }

        &:before {
          top: -15px;
          left: 0;

          @include media-down(laptop) {
            transform: rotate(-11deg);
            top: 0;
            left: 5px;
          }
        }

        &:after {
          bottom: -20px;
          right: -20px;

          @include media-down(laptop) {
            transform: rotate(-11deg);
            right: 5px;
          }
        }
      }
    }

    .sfe-supports__classruk_image {
      width: 299px;
      flex-shrink: 0;
      object-fit: contain;
      object-position: bottom;

      @include media-down(desktop) {
        width: 210px;
      }

      @include media-down(laptop) {
        width: 153px;
        order: 1;
      }

      @include media-down(mobile) {
        margin-top: -40px;
        margin-right: -13px;
        z-index: 2;
      }
    }

    .sfe-supports_item_content {
      margin-left: 40px;

      @include media-down(desktop) {
        margin-left: 10px;
      }

      @include media-down(laptop) {
        margin-left: 0;
        margin-top: auto;
        width: calc(100% - 153px);
      }

      @include media-down(mobile) {
        padding-left: 16px;
        width: calc(100% - 140px);
      }
    }
  }

  .sfe-supports__tutor {
    display: flex;
    overflow: hidden;

    @include media-down(laptop) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .sfe-supports_item__figure {
      width: 403px;
      flex-shrink: 0;
      top: 30px;

      @include media-down(laptop) {
        transform: rotate(-11deg);
        order: 0;
        width: 312px;
        height: 165px;
      }

      @include media-down(mobile) {
        margin-top: -40px;
      }

      p {
        width: 305px;
        top: 21%;
        left: 7%;
        transform: rotate(11deg);

        @include media-down(laptop) {
          width: 270px;
          top: 32%;
        }

        &:before {
          top: -15px;
          left: 0;

          @include media-down(laptop) {
            transform: rotate(-11deg);
            top: -20px;
            left: 0;
          }
        }

        &:after {
          bottom: -20px;
          right: -30px;

          @include media-down(laptop) {
            transform: rotate(-11deg);
            bottom: -25px;
            right: 0;
          }
        }
      }
    }

    .sfe-supports__tutor_image {
      width: 301px;
      flex-shrink: 0;
      object-fit: contain;
      object-position: bottom;

      @include media-down(desktop) {
        width: 250px;
      }

      @include media-down(laptop) {
        order: 1;
        width: 168px;
        margin-top: -40px;
        z-index: 2;
      }

      @include media-down(mobile) {
        margin-right: -10px;
        margin-top: -35px;
      }
    }

    .sfe-supports_item_content {
      margin-right: 40px;

      @include media-down(desktop) {
        margin-right: 10px;
      }

      @include media-down(laptop) {
        order: 1;
        margin-right: 0;
        margin-top: auto;
        width: calc(100% - 168px);
      }

      @include media-down(mobile) {
        width: calc(100% - 158px);
        padding-left: 16px;
      }
    }
  }

  .sfe-supports__mentor {
    display: flex;
    overflow: hidden;

    @include media-down(laptop) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .sfe-supports_item__figure {
      top: 17px;
      left: -10px;

      width: 403px;
      flex-shrink: 0;

      transform: rotate(-8.392deg);

      @include media-down(laptop) {
        top: 40px;
        left: 0;

        width: 312px;
        height: 150px;

        transform: rotate(0deg);
      }

      p {
        top: 55px;
        left: 53px;

        width: 297px;

        @include media-down(laptop) {
          width: 270px;
          left: 21px;
          top: 28px;
        }

        &:before {
          top: -20px;
          left: -15px;

          @include media-down(laptop) {
            transform: rotate(-10deg);
            top: -6px;
            left: 4px;
          }
        }

        &:after {
          bottom: -20px;
          right: -5px;

          @include media-down(laptop) {
            transform: rotate(-10deg);
            bottom: -10px;
            right: 6px;
          }
        }
      }
    }

    .sfe-supports__classruk_image {
      width: 310px;
      flex-shrink: 0;
      object-fit: contain;
      object-position: bottom;

      @include media-down(desktop) {
        width: 270px;
      }

      @include media-down(laptop) {
        width: 180px;
        order: 1;
      }

      @include media-down(mobile) {
        margin-top: -50px;
        margin-right: -30px;
        z-index: 2;
      }
    }

    .sfe-supports_item_content {
      margin-left: 30px;

      @include media-down(desktop) {
        margin-left: 10px;
      }

      @include media-down(laptop) {
        margin-left: 0;
        margin-top: auto;
        width: calc(100% - 180px);
      }

      @include media-down(mobile) {
        width: calc(100% - 150px);
        padding-left: 16px;
      }
    }
  }

  .sfe-supports_video-item {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    overflow: hidden;

    @include media-down(tablet) {
      margin-top: 16px;
      position: relative;
      flex: 1;
    }

    .sfe-supports_video-item__image {
      max-width: 678px;
      width: 100%;
      position: relative;
      top: 3px;

      @include media-down(tablet) {
        position: absolute;
        width: auto;
        top: auto;
        right: 0;
        bottom: 0;
      }
    }

    p {
      flex-shrink: 0;
      max-width: 493px;
      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;
      margin-bottom: auto;

      @include media-down(desktop) {
        font-size: 22px;
      }

      @include media-down(laptop) {
        font-size: 18px;
        max-width: 370px;
        padding-bottom: 10px;
      }

      @include media-down(tablet) {
        font-size: 16px;
        max-width: 100%;
        line-height: 120%;
      }

      @include media-down(mobile) {
        padding-left: 16px;
        padding-right: 16px;
      }

      button {
        display: flex;
        align-items: center;
        gap: 10px;
        color: $black;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;

        @include media-down(laptop) {
          font-size: 16px;
        }

        @include media-down(tablet) {
          font-size: 14px;
          margin-top: 20px;
          gap: 6px;
        }

        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;

          @include media-down(tablet) {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  .sfe-supports__proforientolog {
    p button img {
      background-color: #73b0f4;
    }

    a {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 30px;

      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: #D56FAC;
    }

    .sfe-supports_video-item__play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    @include media-down(tablet) {
      .sfe-supports_video-item__image {
        height: 170px;
      }
    }

    @include media-down(mobile) {
      .sfe-supports_video-item__image {
        bottom: -10px;
      }
    }
  }

  .sfe-supports__psychologist {
    p button img {
      background-color: $blue;
    }

    @include media-down(tablet) {
      .sfe-supports_video-item__image {
        height: 170px;
      }
    }

    p {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: 0;
    }

    .sfe-supports__psychologist__icons {
      margin-top: auto;
      height: 70px;
      display: flex;
      align-items: flex-end;
      gap: 4px;
      position: relative;
      z-index: 2;

      @include media-down(laptop) {
        gap: 0;
      }

      @include media-down(mobile) {
        justify-content: center;
        padding-right: 150px;

        &:after {
          content: '';
          position: absolute;
          width: 15px;
          height: 29px;
          background-image: url('/v2/main/sfe-support/zzz.svg');
          background-repeat: no-repeat;
          background-size: contain;
          left: calc(50% - 45px);
          top: -20px;
        }
      }

      @include media-down(mobile) {
        .sfe-supports__psychologist__icon-1,
        .sfe-supports__psychologist__icon-5 {
          display: none;
        }

        .sfe-supports__psychologist__icon-3 {
          margin-left: -10px;
          margin-right: -12px;
          position: relative;
          z-index: 2;
        }
      }

      .sfe-supports__psychologist__icon-4 {
        width: 80px;
        object-fit: cover;
      }

      .sfe-supports__psychologist__icon-5 {
        transform: translateY(5px);
      }

      img {
        transition: 200ms;
        transform: translateY(10px);
        width: fit-content;

        &:hover {
          transform: translateY(0);
        }
      }
    }
  }
}
</style>
