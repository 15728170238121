<template>
  <div :class="['sfe__block sfe__license', { 'sfe__license--ceny': cenyPage }]">
    <gos-licenses-modal
      v-if="licensesModal"
      @close="licensesModal = false"
    />

    <h3 class="title-secondary">
      <span>{{ title }}</span>
    </h3>
    <button
      class="m-btn"
      @click="licensesModal = true"
    >
      Проверить лицензию

      <img
        loading="lazy"
        src="/v2/main/sfe/arrow.png"
        alt="arrow"
        width="8"
        height="24"
      >
    </button>
  </div>
</template>

<script>
import GosLicensesModal from '@/components/v2/common/licenses/GosLicensesModal.vue';

export default {
  name: 'GosLicenses',
  components: { GosLicensesModal },
  props: {
    title: {
      type: String,
      default: 'Государственная лицензия',
    },
  },
  data: () => ({
    licensesModal: false,
  }),
  computed: {
    cenyPage() {
      return this.$route.name === 'online-school-ceny';
    },
  },
};
</script>

<style scoped lang="scss">
.sfe__block {
  padding: 35px 40px 30px;
  background-color: $white-color;
  border-radius: 20px;
  border: 2px solid $black;
  box-shadow: 4px 4px 0 0 $black;
  height: 580px;

  @include media-down(laptop) {
    height: auto;
    min-height: 500px;
  }

  @include media-down(tablet) {
    min-height: auto;
    box-shadow: 2px 4px 0 0 $black;
    border-radius: 16px;
    padding: 16px;
  }

  h3 {
    letter-spacing: -1.35px;
    text-transform: uppercase;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;

    @include media-down(tablet) {
      max-width: 280px;
      margin-bottom: 8px;
    }

    span {
      position: relative;
      z-index: 2;
    }

    &:before,
    &:after {
      content: '';
      height: 32px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 5px;

      @include media-down(tablet) {
        top: 2px;
        height: 23px;
      }
    }
  }
}

.sfe__license {
  width: 532px;
  flex-shrink: 0;
  background-image: url('/v2/main/sfe/licenses.webp');
  background-repeat: no-repeat;
  background-position: right bottom -1px;

  @include media-down(laptop) {
    width: 100%;
  }

  @include media-down(tablet) {
    min-height: 304px;
    padding-bottom: 140px;
    background-size: auto 70%;
    background-image: url('/v2/main/sfe/licenses-mob.webp');
  }

  @include media-down(mobile) {
    background-size: auto 58%;
  }

  h3:before {
    width: 416px;
    background-color: $green;

    @include media-down(laptop) {
      width: 655px;
    }

    @include media-down(tablet) {
      width: 270px;
    }
  }

  h3:after {
    top: 55px;
    width: 242px;
    background-color: $green;

    @include media-down(laptop) {
      width: 0;
    }

    @include media-down(tablet) {
      width: 155px;
      top: 36px;
    }
  }

  button {
    color: $black;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 0 0;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    @include media-down(tablet) {
      gap: 6px;
      padding: 0;
      font-size: 16px;
    }

    @include media-down(mobile) {
      font-size: 14px;
    }

    img {
      @include media-down(tablet) {
        margin-top: -2px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      transition: 300ms;
      height: 2px;
      width: 0;
      background-color: $black;
    }

    &:hover:after {
      width: 100%;
    }
  }
}

.sfe__license--ceny {
  background-image: url("/v2/main/sfe/gerb-desktop.svg");
  background-position: left bottom;

  @include media-down(tablet) {
    background-image: url("/v2/main/sfe/gerb-mobile.svg");
    background-size: 280px;
    background-position: right bottom;
  }

  button {
    color: $blue-dark;
  }
}
</style>
