<template>
  <div :class="['sfe__block sfe__certificate', { 'sfe__certificate--ceny': cenyPage }]">
    <h3 class="title-secondary">
      <span>аттестат Гос. образца</span>
    </h3>
    <p class="text-primary">
      Школьная программа построена по&nbsp;российскому стандарту образования ФГОС и&nbsp;ФООП.
      Итоговые оценки ставятся по&nbsp;результатам онлайн-аттестации в&nbsp;нашей школе
    </p>

    <small>
      *это важно, ведь&nbsp;90% онлайн-школ не&nbsp;выдают аттестат гос. образца
      самостоятельно, а&nbsp;делают это&nbsp;через партнеров
    </small>
  </div>
</template>

<script>
export default {
  name: 'SfeCertificate',
  computed: {
    cenyPage() {
      return this.$route.name === 'online-school-ceny';
    },
  },
};
</script>

<style scoped lang="scss">

.sfe__block {
  padding: 35px 40px 30px;
  background-color: $white-color;
  border-radius: 20px;
  border: 2px solid $black;
  box-shadow: 4px 4px 0 0 $black;
  height: 580px;

  @include media-down(laptop) {
    height: auto;
    min-height: 500px;
  }

  @include media-down(tablet) {
    min-height: auto;
    box-shadow: 2px 4px 0 0 $black;
    border-radius: 16px;
    padding: 16px;
  }

  .title-secondary {
    letter-spacing: -1.35px;
    width: fit-content;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;

    @include media-down(tablet) {
      max-width: 280px;
      margin-bottom: 8px;
    }

    span {
      position: relative;
      z-index: 2;
    }

    &:before,
    &:after {
      content: '';
      height: 32px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 5px;

      @include media-down(tablet) {
        top: 2px;
        height: 23px;
      }
    }
  }

  .text-primary {
    font-variant-numeric: lining-nums proportional-nums;
    letter-spacing: -0.2px;
    position: relative;
    z-index: 2;
  }
}

.sfe__certificate {
  display: flex;
  flex-direction: column;
  background-image: url('/v2/main/sfe/certificate-gerb.svg');
  background-repeat: no-repeat;
  position: relative;
  width: 100%;

  @include media-down(tablet) {
    background-size: 80% auto;
  }

  h3:before {
    background-color: #fbd277;
    width: 508px;

    @media (max-width: 1200px) {
      width: 310px;
    }

    @include media-down(laptop) {
      width: 508px;
    }

    @include media-down(tablet) {
      width: 200px;
    }
  }

  h3:after {
    @media (max-width: 1200px) {
      background-color: #fbd277;
      width: 210px;
      top: 55px;
    }

    @include media-down(laptop) {
      width: 0;
    }

    @include media-down(tablet) {
      width: 135px;
      top: 36px;
    }
  }

  p {
    max-width: 555px;

    @include media-down(tablet) {
      max-width: 400px;
    }
  }

  small {
    margin-top: auto;
    max-width: 570px;
    color: #868f9f;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 18px;
    font-weight: 700;
    line-height: 130%;

    @include media-down(tablet) {
      margin-top: 100px;
      font-size: 14px;
    }

    @include media-down(mobile) {
      margin-top: 49px;
      font-size: 14px;
    }
  }
}

.sfe__certificate--ceny {
  background-image: url('/v2/main/sfe/attestat.webp');
  background-position: right bottom;
  background-size: 410px;

  @include media-down(tablet) {
    background-size: 195px;
  }

  &::after {
    content: none !important;
  }

  .text-primary {
    @include media-down(tablet) {
      font-size: 14px;
    }
  }

  small {
    @include media-down(tablet) {
      max-width: 193px;
    }
  }
}

</style>
