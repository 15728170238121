<template>
  <div class="advantages">
    <div
      id="onlineSchoolAdvantagesContainer"
      class="advantages__container"
    >
      <img
        v-if="showIcons"
        id="advantages__figure-baza"
        loading="lazy"
        class="advantages__figure-baza"
        src="/v2/school/advantages/baza.webp"
        alt="baza"
        :width="isLaptop ? '100' : '238'"
        :height="isLaptop ? '76' : '180'"
      >
      <img
        v-if="showIcons"
        id="advantages__figure-1"
        loading="lazy"
        class="advantages__figure-1"
        src="/v2/school/advantages/figure-1.webp"
        alt="figure 1"
        :width="isTablet ? '58' : isLaptop ? '100' : '176'"
        :height="isTablet ? '56' : isLaptop ? '97' : '170'"
      >
      <img
        v-if="showIcons"
        id="advantages__figure-2"
        loading="lazy"
        class="advantages__figure-2"
        src="/v2/school/advantages/figure-2.webp"
        alt="figure 2"
        :width="isTablet ? '67' : isLaptop ? '110' : '151'"
        :height="isTablet ? '67' : isLaptop ? '110' : '151'"
      >

      <h2 v-html="title" />

      <div
        :class="['advantages__row-blocks', { 'advantages__row-blocks--ceny': cenyPage }]"
      >
        <gos-licenses />

        <sfe-certificate />

        <img
          v-if="showIcons"
          id="advantages__row-blocks__img"
          loading="lazy"
          class="advantages__row-blocks__img"
          src="/v2/school/advantages/snake.webp"
          alt="icon"
          :width="isTablet ? '164' : isLaptop ? '200' : '274'"
          :height="isTablet ? '70' : isLaptop ? '86' : '117'"
        >
      </div>

      <sfe-comfortable
        :initial-slide-index="3"
      />

      <sfe-supports
        :default-button-id="2"
        :hide-button-ids="[1]"
      />
    </div>
  </div>
</template>

<script>
import SfeCertificate from '@/components/v2/main/sfe/SfeCertificate.vue';
import GosLicenses from '@/components/v2/common/licenses/GosLicenses.vue';
import SfeComfortable from '@/components/v2/main/sfe/SfeComfortable.vue';
import SfeSupports from '@/components/v2/main/sfe/SfeSupports.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SchoolAdvantages',
  components: {
    SfeSupports,
    SfeComfortable,
    GosLicenses,
    SfeCertificate,
  },
  data: () => ({
    showIcons: false,
  }),
  computed: {
    ...mapGetters({
      isLaptop: 'isLaptop',
      isTablet: 'isTablet',
    }),
    cenyPage() {
      return this.$route.name === 'online-school-ceny';
    },
    zaochnoePage() {
      return this.$route.name === 'online-school-zaochnoe-obuchenie';
    },
    classPage() {
      return Boolean(this.$route.name.match(/online-school-[0-9]{1,2}-klass/));
    },
    title() {
      if (this.zaochnoePage) {
        return 'все преимущества <span>заочной</span> школы';
      }
      if (this.classPage) {
        const classNumber = this.$route.name.match(/[0-9]{1,2}/)?.[0] || 8;
        return `Как устроено онлайн-обучение  <span>в ${classNumber} классе</span>?`;
      }

      return 'все <span>преимущества</span> классической школы';
    },
  },
  mounted() {
    this.showIcons = true;
  },
};
</script>

<style scoped lang="scss">
.advantages {
  overflow: hidden;
  padding-bottom: 4px;

  .advantages__container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: min(1320px, calc(100% - 16px));

    display: flex;
    flex-direction: column;
    gap: 40px;

    @include media-down(laptop) {
      gap: 20px;
    }
  }

  h2 {
    color: $black;
    font-size: 65px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.3px;
    text-transform: uppercase;

    max-width: 900px;
    margin-bottom: 10px;
    padding-left: 40px;

    @include media-down(laptop) {
      font-size: 50px;
    }

    @include media-down(tablet) {
      margin-bottom: 20px;
      padding-left: 0;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;

      font-size: 40px;
      line-height: 110%;
      letter-spacing: -0.9px;
      text-align: center;
    }

    @include media-down(mobile) {
      font-size: 30px;
    }

    ::v-deep(span) {
      color: #ff5151;
    }
  }

  .advantages__row-blocks {
    display: flex;
    gap: 40px;
    position: relative;

    @include media-down(laptop) {
      flex-direction: column;
      gap: 20px;
    }

    .advantages__row-blocks__img {
      width: 274px;
      position: absolute;
      bottom: -104px;
      right: 414px;
      z-index: 1;

      @include media-down(laptop) {
        width: 200px;
        right: 150px;
        bottom: -67px;
      }

      @include media-down(tablet) {
        width: 164px;
        bottom: -58px;
      }

      @include media-down(mobile) {
        right: 140px;
      }
    }
  }

  .advantages__row-blocks--ceny {
    flex-direction: row-reverse;

    @include media-down(laptop) {
      flex-direction: column-reverse;
    }
  }

  ::v-deep .sfe__license {
    @include media-down(desktop) {
      width: 45%;
    }

    @include media-down(laptop) {
      width: 100%;
      z-index: 3;
    }
  }

  ::v-deep .sfe__certificate {
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 450px;
      height: 445px;
      right: 0;
      bottom: 0;

      background-image: url('/v2/school/advantages/certificate_bg.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;

      @include media-down(desktop) {
        width: 300px;
      }

      @include media-down(tablet) {
        content: none;
      }
    }

    small {
      @include media-up(tablet) {
        max-width: 240px;
      }
    }
  }

  ::v-deep .sfe-supports {
    z-index: 2;

    .sfe-supports__btns {
      max-width: 100%;
    }

    .sfe-supports_item {
      margin-top: 80px;

      @include media-down(laptop) {
        margin-top: auto;
      }
    }
  }

  ::v-deep .sfe__comfortable {
    @include media-down(laptop) {
      margin-bottom: 0;
    }

    .sfe__comfortable__try--mobile {
      display: none;
    }

    .sfe__comfortable_btns {
      @include media-down(laptop) {
        order: 1;
        margin-top: 32px;
      }
    }

    .sfe__comfortable__try {
      display: none;
    }
  }

  // images

  .advantages__figure-baza {
    position: absolute;
    width: 238px;
    right: -20px;
    top: 20px;
    z-index: 2;

    @include media-down(laptop) {
      width: 100px;
      right: -10px;
      top: 66px;
      z-index: 1;
    }
  }

  .advantages__figure-1 {
    position: absolute;
    width: 176px;
    left: -100px;
    top: 370px;

    @include media-down(laptop) {
      width: 100px;
      left: -25px;
      top: 570px;
      z-index: 3;
    }

    @include media-down(tablet) {
      width: 58px;
      top: 410px;
      left: -17px;
    }
  }

  .advantages__figure-2 {
    position: absolute;
    width: 151px;
    right: -87px;
    bottom: 550px;
    z-index: 2;

    @include media-down(laptop) {
      width: 110px;
      right: -33px;
      bottom: 540px;
    }

    @include media-down(tablet) {
      width: 67px;
      right: -23px;
    }
  }
}
</style>
