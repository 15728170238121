<template>
  <div
    class="sfe-supports__slider"
    :style="sliderDynamicStyles"
  >
    <button
      class="sfe-supports__slider_info m-btn"
      @click="info = !info"
    >
      <img
        v-show="info"
        src="/v2/common/close.svg"
        alt="close"
        loading="lazy"
      >
      <img
        v-show="!info"
        src="/v2/common/info.svg"
        alt="info"
        loading="lazy"
      >
    </button>
    <button
      v-show="!info"
      class="sfe-supports__slider_prev m-btn"
      @click="prevSlide"
    >
      <img
        src="/v2/main/arrow-left.svg"
        alt="prev"
        loading="lazy"
      >
    </button>
    <button
      v-show="!info"
      class="sfe-supports__slider_next m-btn"
      @click="nextSlide"
    >
      <img
        src="/v2/main/arrow-right.svg"
        alt="next"
        loading="lazy"
      >
    </button>

    <div
      :key="slideToView"
      class="sfe-supports__slider__container"
    >
      <div
        v-for="(slide, index) in slides"
        :key="index"
        :style="getSlideStyles(index)"
        class="sfe-supports__slide"
        @click="info = false"
        @mouseover="onSlideOver(index + 1, $event)"
        @mousemove="onSlideMove"
        @mouseleave="onSlideLeave"
      >
        <img
          :src="`/v2/main/sfe-support/${slide.image}`"
          :alt="slide.name"
          loading="lazy"
        >
        <p
          v-show="!containerOverflow"
          :class="[
            'sfe-supports__slide_text',
            `sfe-supports__slide_text--comment-${slide.textImagePosition}`,
            { 'sfe-supports__slide_text--opened': info },
          ]"
          :style="overSlideNumber === (index + 1) ? getSlideTextStyles : {}"
        >
          <span>{{ slide.name }}</span>
          {{ slide.about }},
          <br>
          Опыт педагога: {{ slide.experience }} лет
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash/throttle';
import { mapGetters } from 'vuex';

export default {
  name: 'SfeSupportTeachers',

  data: () => ({
    slides: [
      {
        name: 'Майская Татьяна',
        about: 'Магистр филологии (МГУ)',
        textImagePosition: 'right',
        experience: 8,
        image: 'русский-1.webp',
      },
      {
        name: 'Модебадзе Мардон',
        about: 'Магистр права (НИУ ВШЭ)',
        textImagePosition: 'right',
        experience: 11,
        image: 'общество.webp',
      },
      {
        name: 'Веденьева Марина',
        about: 'Магистр лингвистики и межкультурных коммуникаций (ЛГПУ)',
        textImagePosition: 'left',
        experience: 7,
        image: 'английский.webp',
      },
      {
        name: 'Козлова Анастасия',
        about: 'Магистр педагогики (НИУ ВШЭ)',
        textImagePosition: 'right',
        experience: 12,
        image: 'математика.webp',
      },
      {
        name: 'Громов Александр',
        about: 'Магистр физики (МГУ)',
        textImagePosition: 'right',
        experience: 9,
        image: 'физика.webp',
      },
      {
        name: 'Фролова Александра',
        about: 'Магистр педагогики (МГОУ)',
        textImagePosition: 'left',
        experience: 7,
        image: 'русский.webp',
      },
      {
        name: 'Корнеева Маргарита',
        about: 'Магистр лингвистики, МГПУ',
        textImagePosition: 'left',
        experience: 6,
        image: 'испанский.webp',
      },
      {
        name: 'Германович Олег',
        about: 'Магистр прикладной математики и физики (МГУ)',
        textImagePosition: 'right',
        experience: 7,
        image: 'математика-1.webp',
      },
      {
        name: 'Алимова Наиля',
        about: 'Магистр естественных наук (МГУ)',
        textImagePosition: 'left',
        experience: 7,
        image: 'география.webp',
      },
    ],
    currentSlide: 1,
    info: false,
    containerOverflow: false,

    overSlideNumber: null,
    currentMousePositionOnSlide: null,
  }),

  computed: {
    ...mapGetters({
      isTablet: 'isTablet',
      windowSize: 'getWindowSize',
    }),
    slideToView() {
      return this.isTablet ? 1 : 3;
    },
    sliderDynamicStyles() {
      if (!this.containerOverflow) return null;
      return {
        overflow: 'hidden',
      };
    },
    getSlideTextStyles() {
      if (this.isTablet) return {};
      if (!this.currentMousePositionOnSlide) return {};

      const slideImageLeft = this.slides[this.overSlideNumber - 1]?.textImagePosition === 'left';
      const left = this.currentMousePositionOnSlide.x - (slideImageLeft ? 90 : 220);
      const top = this.currentMousePositionOnSlide.y + 10;

      return {
        // так производительнее, но зависает
        // transform: `translate(${left}px, ${top}px)`,
        left: `${left}px`,
        top: `${top}px`,
      };
    },
  },
  watch: {
    windowSize() {
      this.currentSlide = 1;
      this.overSlideNumber = null;
      this.currentMousePositionOnSlide = null;
      this.info = false;
    },
  },

  methods: {
    checkIsVisible(slideNumber) {
      return (this.currentSlide * this.slideToView) >= slideNumber
        && ((this.currentSlide - 1) * this.slideToView) < slideNumber;
    },
    getSlideStyles(slideIndex) {
      const slideVisible = this.checkIsVisible(slideIndex + 1);
      return {
        position: slideVisible ? 'relative' : 'absolute',
        // eslint-disable-next-line no-nested-ternary
        left: slideVisible ? 0 : this.isTablet ? '1000px' : '3000px',
        transitionDelay: slideVisible && !this.isTablet ? `${(slideIndex % this.slideToView) * 70}ms` : '0ms',
        transitionDuration: slideVisible ? '500ms' : '0ms',
        opacity: slideVisible ? 1 : 0,
      };
    },

    setContainerOverflowHidden() {
      this.containerOverflow = true;

      setTimeout(() => {
        this.containerOverflow = false;
      }, 600);
    },
    prevSlide() {
      this.setContainerOverflowHidden();
      this.info = false;
      if (this.currentSlide === 1) {
        this.currentSlide = Math.ceil(this.slides.length / this.slideToView);
      } else {
        this.currentSlide -= 1;
      }
    },
    nextSlide() {
      this.setContainerOverflowHidden();
      this.info = false;
      if (this.currentSlide === Math.ceil(this.slides.length / this.slideToView)) {
        this.currentSlide = 1;
      } else {
        this.currentSlide += 1;
      }
    },

    onSlideOver(slideNumber, e) {
      if (this.isTablet) {
        return;
      }

      this.currentMousePositionOnSlide = {
        x: e.layerX,
        y: e.layerY,
      };
      this.overSlideNumber = slideNumber;
    },
    // eslint-disable-next-line func-names
    onSlideMove: throttle(function (e) {
      if (this.isTablet) {
        return;
      }

      this.currentMousePositionOnSlide = {
        x: e.layerX,
        y: e.layerY,
      };
    }, 100),
    onSlideLeave() {
      this.currentMousePositionOnSlide = null;
      this.overSlideNumber = null;
    },
  },
};
</script>

<style scoped lang="scss">

.sfe-supports__slider {
  position: relative;
  flex: 1;

  @include media-down(mobile) {
    height: 322px;
    overflow: hidden;
  }

  .sfe-supports__slider_info {
    @include media-up(tablet) {
      display: none;
    }

    position: absolute;
    left: 8px;
    top: 12px;
    border-radius: 50%;
    box-shadow: 2px 2px 0 0 $black;
    width: 44px;
    height: 44px;
    padding: 0;
    background-color: $gray-bg;
    z-index: 3;
    transition: 200ms;

    &:hover {
      box-shadow: none;
      transform: translate(2px, 2px);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .sfe-supports__slider_prev,
  .sfe-supports__slider_next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 100ms;
    width: 62px;
    z-index: 2;
    filter: drop-shadow(2px 2px 0 $black);

    &:hover {
      filter: none;
      transform: translate(2px, calc(-50% + 2px));
    }

    @include media-down(laptop) {
      width: 50px;
      top: 60%;
    }

    @include media-down(tablet) {
      width: 44px;
      filter: drop-shadow(2px 2px 0 $black);
    }

    img {
      width: 100%;
      border-radius: 50%;
      background-color: $white-color;

      @include media-down(tablet) {
        background-color: $gray-bg;
      }
    }
  }

  .sfe-supports__slider_prev {
    left: 30px;

    @include media-down(tablet) {
      left: 6px;
    }
  }

  .sfe-supports__slider_next {
    right: 30px;

    @include media-down(tablet) {
      right: 6px;
    }
  }

  .sfe-supports__slider__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: calc(100% - 210px);
    margin: 0 auto;
    position: relative;
    top: 3px;
    height: 100%;

    @include media-down(laptop) {
      width: calc(100% - 50px);
    }

    @include media-down(tablet) {
      width: 100%;
      justify-content: center;
    }
  }

  .sfe-supports__slide {
    width: 31%;
    flex-shrink: 0;
    position: relative;
    transition-timing-function: ease-out;

    @include media-down(laptop) {
      width: 32%;
    }

    @include media-down(tablet) {
      width: min(80%, 350px);
    }

    img {
      width: 100%;
    }

    .sfe-supports__slide_text--comment-right {
      background-image: url("/v2/main/sfe-support/comment-right.svg");

      @include media-down(tablet) {
        left: 50% !important;
        transform: translateX(-50%);
      }
    }

    .sfe-supports__slide_text--comment-left {
      background-image: url("/v2/main/sfe-support/comment-left.svg");
      width: 345px;

      @include media-down(tablet) {
        width: 325px;
        left: 50% !important;
        transform: translateX(-50%);
      }
    }

    .sfe-supports__slide_text {
      position: absolute;
      top: 0;
      left: 0;

      display: none;
      width: 305px;
      height: 205px;
      background-size: contain;
      background-repeat: no-repeat;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px 10px 0;

      color: $black;
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 18px;
      font-weight: 700;
      line-height: 100%;

      pointer-events: none;
      transition-duration: 100ms;

      @include media-down(tablet) {
        top: 70px;
        font-size: 16px;
        background-image: url("/v2/main/sfe-support/comment-mobile.webp");
        width: 298px;
        height: 162px;
        padding-top: 10px;
        max-width: calc(100vw - 20px);
      }

      span {
        font-size: 30px;
        font-weight: 600;
        line-height: 90%;

        @include media-down(tablet) {
          font-size: 25px;
          font-weight: 700;
          line-height: 130%;
          margin-bottom: 5px;
          display: inline-block;
        }
      }
    }

    &:has(.sfe-supports__slide_text--opened) img {
      filter: blur(3px);
    }

    .sfe-supports__slide_text--opened {
      display: flex;
    }

    @include media-up(tablet) {
      &:hover .sfe-supports__slide_text {
        display: flex;
      }
    }
  }
}

</style>
